<template>
    <div class="sst">
        <el-dialog v-drag :close-on-click-modal="false" center :visible.sync="dialogVisible" width="70%">
            <div slot="title">
                <h3>{{ source.title }}</h3>
            </div>
            <div>
                <div class="flex flex-wrap flex-v-center">
                    <template v-for="(word, index) in words">
                        <el-input :disabled="submitted" v-model="content[word.i]" clearable
                            style="width: auto;margin:5px;" size="small" :key="'k1' + index"
                            v-if="word.i != undefined"></el-input>
                        <span :key="'k2' + index" class="mt" v-else>{{ word }}</span>
                        <span class="st" v-if="!submitted && word.i != undefined" :key="'k3' + index">({{
                            answers[word.i].split(' ').length + $t('词') }})</span>
                        <template class="flex flex-v-center" v-if="submitted && word.i != undefined">
                            <i :key="'k3' + index"
                                v-if="content[word.i] && content[word.i].toLowerCase() == answers[word.i].toLowerCase()"
                                style="color:green;font-weight:bold;font-size:20px;" class="el-icon-check"></i>
                            <i :key="'k4' + index" v-else style="color: #ff0000;font-size:20px;font-weight: bold;"
                                class="el-icon-close pointer"></i>

                            <span v-if="content[word.i].toLowerCase() != answers[word.i].toLowerCase()"
                                :key="'k5' + index" class="word wrong">( {{ answers[word.i] }} )</span>
                        </template>
                    </template>
                </div>
            </div>
            <div slot="footer" style="position: relative">

                <el-switch style="position: absolute;left:0;top:10px;left:10px;" :active-text="$t('答案')"
                    active-color="#E6A23C" inactive-text="" v-model="submitted"></el-switch>

                <div class="flex1">
                    <el-button round style="width:100px" @click="dialogVisible = false">{{ $t('关闭') }}</el-button>

                    <el-button type="primary" :disabled="submitted" round style="width:100px;margin-left: 100px;"
                        @click="submit">{{
                            $t('提交') }}</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import drag from './drag'
export default {
    name: "sstDialog",
    directives: {
        drag
    },
    data() {
        return {
            submitted: false,
            words: "",
            answers: "",
            content: [],
            dialogVisible: false,
            source: {},
        };
    },
    methods: {
        show(source) {

            this.source = source
            this.init();
            this.dialogVisible = true;

        },

        init() {
            this.submitted = false;
            this.content = []
            let regex = /\([^)]+\)/g;
            let answers = this.source.sst_keywords.match(regex)
            answers.forEach((answer, index) => {
                answers[index] = answer.replace(/[()]/g, '');
            });
            this.answers = answers;
            let sst_keywords = this.source.sst_keywords.replace(regex, "_");
            let words = [...sst_keywords]
            let i = 0;
            words.forEach((word, index) => {
                if (word == '_') {
                    this.content[i] = ""
                    words[index] = { i: i };
                    i++
                }
            })
            this.words = words
        },

        submit() {
            console.log(this.content)
            let flag = true;
            this.content.forEach((item) => {
                if (item) flag = false;
            })
            if (flag) {
                this.$message.info("请至少输入一个答案")
            } else {
                let score = 0;
                let tag = 0;
                let content = [];

                this.content.forEach((val, index) => {
                    if (val) this.content[index] = val.replace(/(^\s*)|(\s*$)/g, '').replace(/\s+/g, " ")
                })

                this.answers.forEach((answer, index) => {
                    tag = 0;
                    if (this.content[index] && this.content[index].toLowerCase() == answer.toLowerCase()) {
                        score++
                        tag = 1;
                    }
                    content[index] = this.content[index] + "/" + answer + "/" + tag;
                })
                this.$http.post("/api/source/work/record", { source_id: this.source.id, content: content, score: score }).then(() => {
                    this.submitted = true;
                    this.$message.success(this.$t("提交成功"))
                    this.$emit("submited")
                })
            }
        }
    },
};
</script>
<style scoped>
.sst>>>.el-input__inner {
    color: #e6a13c;
    font-weight: bold;
    font-size: 16px;
}

.word.wrong {
    color: #ff0000;
}

.word.right {
    color: #00ff00;
}
</style>